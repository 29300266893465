import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import { DownOutlined } from "@ant-design/icons";
import Footer from "../Components/Footer";
import { PopupModal } from "react-calendly";
import LandingSection from "../Components/Utils/LandingSection";
import CTA from "../Components/CTA";

function Compare() {
  const [optionsMenu, setOptionsMenu] = useState(false);
  const options = [
    {
      value: "option1",
      label: "Supermetrics",
      imageUrl: "/Comparison/supermetrics.svg",
      focus: "Data Extraction & Marketing Analytics",
      dataConnectors: "100+ sources (primarily marketing platforms)",
      dataTransformation: "Advanced data transformation capabilities",
      dataDestinations: "Data warehouses, BI tools & marketing platforms",
      reporting: "Focuses on data transfer; needs separate BI tools",
      security: "Secure data transfer",
      pricing: "Recurring monthly fees (plan-based)",
      support: "Knowledge base articles & support tickets",
      sightShark: {
        perfectFor:
          "Businesses needing a flexible solution for data delivery, customization, clear & actionable insights, and cost-effective solutions.",
        keyStrengths: [
          {
            key: "Cost Savings",
            value:
              "A one-time setup fee offers significant savings compared to recurring monthly/annual fees.",
          },
          {
            key: "Customizable Dashboards",
            value:
              "Get dashboards built specifically for your unique needs, not generic marketing templates.",
          },
          {
            key: "Unmatched Security",
            value: "Data remains securely within your cloud environment.",
          },
          {
            key: "Dedicated Support",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
        ],
      },
      opponent: {
        perfectFor:
          "Marketing teams or agencies heavily reliant on marketing data from various platforms and comfortable using separate BI tools for visualization.",
        keyStrengths: [
          {
            key: "Extensive Marketing Connector Library",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
          {
            key: "Seamless BI Integration",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
          {
            key: "Flexible Data Transformation",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
          {
            key: "Scalable Solution",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
        ],
      },
      advantanges: [
        "Supermetrics offers 100+ marketing data source connectors, whereas Sight Shark offers 25+ connectors. You may feel that the number of connectors Sight Shark provides is lower; however, that doesn’t undermine our offerings. We offer the most common data sources in the market, and on top of that, we can build connectors based on our client's requirements.",
        "Sight Shark allows you to choose the metrics and dimensions to extract per your requirements. This means you only pay for and receive the data you actually need. While Supermetrics provides access to all available metrics and dimensions. This can be useful for comprehensive analysis, but it can also be expensive if you only need a specific subset of the data.",
        "Sight Shark provides fully customized dashboards based on the client’s needs to highlight the insights that you want. Based on your requirements, we can also help you build dashboards on Tableau, PowerBI, and Google Looker Studio. Whereas,  Supermetrics focuses on data delivery and in case you need additional support in terms of visualization, you will have to hire someone else.",
        "Sight Shark is 100% secure and private. We exist and operate entirely on your cloud. This means that your data NEVER leaves your cloud and cannot be accessed by any third party. Each Sight Shark setup comes with a 6 months warranty.",
        "Supermetrics starts at $39 per month, and the cost goes higher if you add more than one connector, account, or user. The least expensive option comes in at $1269/month for 5 data sources, 20 accounts, and 3 users using Google Sheets as the destination. Whereas, we charge a one-time setup fee based on the destination and volume of data (no annual or monthly subscription) with an unlimited number of accounts. And we can guarantee that you will have over 50% cost savings over a three-year period.",
        "Supermetrics provides a ticket system where users can submit their inquiries, and a dedicated assistant will respond to the user's email to address their specific needs. Whereas, we will assign a dedicated Project Lead who will be there to help you with your queries anytime. You can chat, email, or set up a meeting, whichever works for you better.",
      ],
      choosing: {
        sightShark:
          "You need flexibility, customization, cost-effectiveness, and want insights tailored to your specific business goals.",
        opponent:
          "You primarily work with marketing data, are comfortable with separate BI tools for visualization, and need to connect to a wider range of marketing-specific platforms.",
      },
    },
    {
      value: "option2",
      label: "StichData",
      imageUrl: "/Comparison/stichdata.svg",
      focus: "Data Pipelines & Transformation",
      dataConnectors:
        "140+ sources (Wide range of databases, SaaS apps, & marketing platforms)",
      dataTransformation: "Clean, filter, and manipulate data for accuracy",
      dataDestinations:
        "Data Warehouses (Redshift, BigQuery, Snowflake) & BI Tools",
      reporting: "Focuses on data transfer; needs separate BI tools",
      security: "Secure data transfer",
      pricing: "Recurring monthly fees (plan-based)",
      support: "Knowledge base articles & support tickets",
      sightShark: {
        perfectFor:
          "Businesses needing a flexible solution for data delivery, customization, clear & actionable insights, and cost-effective solutions.",
        keyStrengths: [
          {
            key: "Cost Savings",
            value:
              "A one-time setup fee offers significant savings compared to recurring monthly/annual fees.",
          },
          {
            key: "Customizable Dashboards",
            value:
              "Get dashboards built specifically for your unique needs, not generic marketing templates.",
          },
          {
            key: "Unmatched Security",
            value: "Data remains securely within your cloud environment.",
          },
          {
            key: "Dedicated Support",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
        ],
      },
      opponent: {
        perfectFor:
          "Marketing teams or agencies heavily reliant on marketing data from various platforms and comfortable using separate BI tools for visualization.",
        keyStrengths: [
          {
            key: "Extensive Marketing Connector Library",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
          {
            key: "Seamless BI Integration",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
          {
            key: "Flexible Data Transformation",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
          {
            key: "Scalable Solution",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
        ],
      },
      advantanges: [
        "Supermetrics offers 100+ marketing data source connectors, whereas Sight Shark offers 25+ connectors. You may feel that the number of connectors Sight Shark provides is lower; however, that doesn’t undermine our offerings. We offer the most common data sources in the market, and on top of that, we can build connectors based on our client's requirements.",
        "Sight Shark allows you to choose the metrics and dimensions to extract per your requirements. This means you only pay for and receive the data you actually need. While Supermetrics provides access to all available metrics and dimensions. This can be useful for comprehensive analysis, but it can also be expensive if you only need a specific subset of the data.",
        "Sight Shark provides fully customized dashboards based on the client’s needs to highlight the insights that you want. Based on your requirements, we can also help you build dashboards on Tableau, PowerBI, and Google Looker Studio. Whereas,  Supermetrics focuses on data delivery and in case you need additional support in terms of visualization, you will have to hire someone else.",
        "Sight Shark is 100% secure and private. We exist and operate entirely on your cloud. This means that your data NEVER leaves your cloud and cannot be accessed by any third party. Each Sight Shark setup comes with a 6 months warranty.",
        "Supermetrics starts at $39 per month, and the cost goes higher if you add more than one connector, account, or user. The least expensive option comes in at $1269/month for 5 data sources, 20 accounts, and 3 users using Google Sheets as the destination. Whereas, we charge a one-time setup fee based on the destination and volume of data (no annual or monthly subscription) with an unlimited number of accounts. And we can guarantee that you will have over 50% cost savings over a three-year period.",
        "Supermetrics provides a ticket system where users can submit their inquiries, and a dedicated assistant will respond to the user's email to address their specific needs. Whereas, we will assign a dedicated Project Lead who will be there to help you with your queries anytime. You can chat, email, or set up a meeting, whichever works for you better.",
      ],
      choosing: {
        sightShark:
          "You need flexibility, customization, cost-effectiveness, and want insights tailored to your specific business goals.",
        opponent:
          "You primarily work with marketing data, are comfortable with separate BI tools for visualization, and need to connect to a wider range of marketing-specific platforms.",
      },
    },
    {
      value: "option3",
      label: "Fivetran",
      imageUrl: "/Comparison/fivetran.svg",
      focus: "Data Extraction & Marketing Analytics",
      dataConnectors: "100+ sources (primarily marketing platforms)",
      dataTransformation: "Advanced data transformation capabilities",
      dataDestinations: "Data warehouses, BI tools & marketing platforms",
      reporting: "Focuses on data transfer; needs separate BI tools",
      security: "Secure data transfer",
      pricing: "Recurring monthly fees (plan-based)",
      support: "Knowledge base articles & support tickets",
      sightShark: {
        perfectFor:
          "Businesses needing a flexible solution for data delivery, customization, clear & actionable insights, and cost-effective solutions.",
        keyStrengths: [
          {
            key: "Cost Savings",
            value:
              "A one-time setup fee offers significant savings compared to recurring monthly/annual fees.",
          },
          {
            key: "Customizable Dashboards",
            value:
              "Get dashboards built specifically for your unique needs, not generic marketing templates.",
          },
          {
            key: "Unmatched Security",
            value: "Data remains securely within your cloud environment.",
          },
          {
            key: "Dedicated Support",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
        ],
      },
      opponent: {
        perfectFor:
          "Marketing teams or agencies heavily reliant on marketing data from various platforms and comfortable using separate BI tools for visualization.",
        keyStrengths: [
          {
            key: "Extensive Marketing Connector Library",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
          {
            key: "Seamless BI Integration",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
          {
            key: "Flexible Data Transformation",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
          {
            key: "Scalable Solution",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
        ],
      },
      advantanges: [
        "Supermetrics offers 100+ marketing data source connectors, whereas Sight Shark offers 25+ connectors. You may feel that the number of connectors Sight Shark provides is lower; however, that doesn’t undermine our offerings. We offer the most common data sources in the market, and on top of that, we can build connectors based on our client's requirements.",
        "Sight Shark allows you to choose the metrics and dimensions to extract per your requirements. This means you only pay for and receive the data you actually need. While Supermetrics provides access to all available metrics and dimensions. This can be useful for comprehensive analysis, but it can also be expensive if you only need a specific subset of the data.",
        "Sight Shark provides fully customized dashboards based on the client’s needs to highlight the insights that you want. Based on your requirements, we can also help you build dashboards on Tableau, PowerBI, and Google Looker Studio. Whereas,  Supermetrics focuses on data delivery and in case you need additional support in terms of visualization, you will have to hire someone else.",
        "Sight Shark is 100% secure and private. We exist and operate entirely on your cloud. This means that your data NEVER leaves your cloud and cannot be accessed by any third party. Each Sight Shark setup comes with a 6 months warranty.",
        "Supermetrics starts at $39 per month, and the cost goes higher if you add more than one connector, account, or user. The least expensive option comes in at $1269/month for 5 data sources, 20 accounts, and 3 users using Google Sheets as the destination. Whereas, we charge a one-time setup fee based on the destination and volume of data (no annual or monthly subscription) with an unlimited number of accounts. And we can guarantee that you will have over 50% cost savings over a three-year period.",
        "Supermetrics provides a ticket system where users can submit their inquiries, and a dedicated assistant will respond to the user's email to address their specific needs. Whereas, we will assign a dedicated Project Lead who will be there to help you with your queries anytime. You can chat, email, or set up a meeting, whichever works for you better.",
      ],
      choosing: {
        sightShark:
          "You need flexibility, customization, cost-effectiveness, and want insights tailored to your specific business goals.",
        opponent:
          "You primarily work with marketing data, are comfortable with separate BI tools for visualization, and need to connect to a wider range of marketing-specific platforms.",
      },
    },
    {
      value: "option3",
      label: "Databloo",
      imageUrl: "/Comparison/databloo.svg",
      focus: "Data Extraction & Marketing Analytics",
      dataConnectors: "100+ sources (primarily marketing platforms)",
      dataTransformation: "Advanced data transformation capabilities",
      dataDestinations: "Data warehouses, BI tools & marketing platforms",
      reporting: "Focuses on data transfer; needs separate BI tools",
      security: "Secure data transfer",
      pricing: "Recurring monthly fees (plan-based)",
      support: "Knowledge base articles & support tickets",
      sightShark: {
        perfectFor:
          "Businesses needing a flexible solution for data delivery, customization, clear & actionable insights, and cost-effective solutions.",
        keyStrengths: [
          {
            key: "Cost Savings",
            value:
              "A one-time setup fee offers significant savings compared to recurring monthly/annual fees.",
          },
          {
            key: "Customizable Dashboards",
            value:
              "Get dashboards built specifically for your unique needs, not generic marketing templates.",
          },
          {
            key: "Unmatched Security",
            value: "Data remains securely within your cloud environment.",
          },
          {
            key: "Dedicated Support",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
        ],
      },
      opponent: {
        perfectFor:
          "Marketing teams or agencies heavily reliant on marketing data from various platforms and comfortable using separate BI tools for visualization.",
        keyStrengths: [
          {
            key: "Extensive Marketing Connector Library",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
          {
            key: "Seamless BI Integration",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
          {
            key: "Flexible Data Transformation",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
          {
            key: "Scalable Solution",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
        ],
      },
      advantanges: [
        "Supermetrics offers 100+ marketing data source connectors, whereas Sight Shark offers 25+ connectors. You may feel that the number of connectors Sight Shark provides is lower; however, that doesn’t undermine our offerings. We offer the most common data sources in the market, and on top of that, we can build connectors based on our client's requirements.",
        "Sight Shark allows you to choose the metrics and dimensions to extract per your requirements. This means you only pay for and receive the data you actually need. While Supermetrics provides access to all available metrics and dimensions. This can be useful for comprehensive analysis, but it can also be expensive if you only need a specific subset of the data.",
        "Sight Shark provides fully customized dashboards based on the client’s needs to highlight the insights that you want. Based on your requirements, we can also help you build dashboards on Tableau, PowerBI, and Google Looker Studio. Whereas,  Supermetrics focuses on data delivery and in case you need additional support in terms of visualization, you will have to hire someone else.",
        "Sight Shark is 100% secure and private. We exist and operate entirely on your cloud. This means that your data NEVER leaves your cloud and cannot be accessed by any third party. Each Sight Shark setup comes with a 6 months warranty.",
        "Supermetrics starts at $39 per month, and the cost goes higher if you add more than one connector, account, or user. The least expensive option comes in at $1269/month for 5 data sources, 20 accounts, and 3 users using Google Sheets as the destination. Whereas, we charge a one-time setup fee based on the destination and volume of data (no annual or monthly subscription) with an unlimited number of accounts. And we can guarantee that you will have over 50% cost savings over a three-year period.",
        "Supermetrics provides a ticket system where users can submit their inquiries, and a dedicated assistant will respond to the user's email to address their specific needs. Whereas, we will assign a dedicated Project Lead who will be there to help you with your queries anytime. You can chat, email, or set up a meeting, whichever works for you better.",
      ],
      choosing: {
        sightShark:
          "You need flexibility, customization, cost-effectiveness, and want insights tailored to your specific business goals.",
        opponent:
          "You primarily work with marketing data, are comfortable with separate BI tools for visualization, and need to connect to a wider range of marketing-specific platforms.",
      },
    },
    {
      value: "option3",
      label: "Porter",
      imageUrl: "/Comparison/porter.svg",
      focus: "Data Extraction & Marketing Analytics",
      dataConnectors: "100+ sources (primarily marketing platforms)",
      dataTransformation: "Advanced data transformation capabilities",
      dataDestinations: "Data warehouses, BI tools & marketing platforms",
      reporting: "Focuses on data transfer; needs separate BI tools",
      security: "Secure data transfer",
      pricing: "Recurring monthly fees (plan-based)",
      support: "Knowledge base articles & support tickets",
      sightShark: {
        perfectFor:
          "Businesses needing a flexible solution for data delivery, customization, clear & actionable insights, and cost-effective solutions.",
        keyStrengths: [
          {
            key: "Cost Savings",
            value:
              "A one-time setup fee offers significant savings compared to recurring monthly/annual fees.",
          },
          {
            key: "Customizable Dashboards",
            value:
              "Get dashboards built specifically for your unique needs, not generic marketing templates.",
          },
          {
            key: "Unmatched Security",
            value: "Data remains securely within your cloud environment.",
          },
          {
            key: "Dedicated Support",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
        ],
      },
      opponent: {
        perfectFor:
          "Marketing teams or agencies heavily reliant on marketing data from various platforms and comfortable using separate BI tools for visualization.",
        keyStrengths: [
          {
            key: "Extensive Marketing Connector Library",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
          {
            key: "Seamless BI Integration",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
          {
            key: "Flexible Data Transformation",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
          {
            key: "Scalable Solution",
            value:
              "Personalized guidance from your assigned Project Lead ensures success.",
          },
        ],
      },
      advantanges: [
        "Supermetrics offers 100+ marketing data source connectors, whereas Sight Shark offers 25+ connectors. You may feel that the number of connectors Sight Shark provides is lower; however, that doesn’t undermine our offerings. We offer the most common data sources in the market, and on top of that, we can build connectors based on our client's requirements.",
        "Sight Shark allows you to choose the metrics and dimensions to extract per your requirements. This means you only pay for and receive the data you actually need. While Supermetrics provides access to all available metrics and dimensions. This can be useful for comprehensive analysis, but it can also be expensive if you only need a specific subset of the data.",
        "Sight Shark provides fully customized dashboards based on the client’s needs to highlight the insights that you want. Based on your requirements, we can also help you build dashboards on Tableau, PowerBI, and Google Looker Studio. Whereas,  Supermetrics focuses on data delivery and in case you need additional support in terms of visualization, you will have to hire someone else.",
        "Sight Shark is 100% secure and private. We exist and operate entirely on your cloud. This means that your data NEVER leaves your cloud and cannot be accessed by any third party. Each Sight Shark setup comes with a 6 months warranty.",
        "Supermetrics starts at $39 per month, and the cost goes higher if you add more than one connector, account, or user. The least expensive option comes in at $1269/month for 5 data sources, 20 accounts, and 3 users using Google Sheets as the destination. Whereas, we charge a one-time setup fee based on the destination and volume of data (no annual or monthly subscription) with an unlimited number of accounts. And we can guarantee that you will have over 50% cost savings over a three-year period.",
        "Supermetrics provides a ticket system where users can submit their inquiries, and a dedicated assistant will respond to the user's email to address their specific needs. Whereas, we will assign a dedicated Project Lead who will be there to help you with your queries anytime. You can chat, email, or set up a meeting, whichever works for you better.",
      ],
      choosing: {
        sightShark:
          "You need flexibility, customization, cost-effectiveness, and want insights tailored to your specific business goals.",
        opponent:
          "You primarily work with marketing data, are comfortable with separate BI tools for visualization, and need to connect to a wider range of marketing-specific platforms.",
      },
    },
  ];
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [contactUsModal, setContactUsModal] = useState(false);
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  return (
    <div className="w-full h-[auto] bg-[#030020] pt-[70px] lg:pt-[120px]">
      <Navbar />
      <LandingSection
        heading="Comparing Sight Shark data solutions with other competitors"
        text="Comparing Sight Shark’s Data Source Connectors, Pricing, Security, Reporting, Destinations, and more with Competitors"
      />
      <div className="w-full flex flex-col justify-center items-center">
        <p className="text-center text-[#AE79D6] otherSectionHeading mb-8 font-light">
          Compare <span className="font-semibold">Sight Shark</span> with
        </p>
        <div className="custom-dropdown text-white relative">
          <div className="selected-option bg-white p-4 text-black">
            {selectedOption ? (
              <div
                className="flex justify-between items-center gap-4 pr-4 cursor-pointer"
                onClick={() => setOptionsMenu(!optionsMenu)}
              >
                <div className="basis-[80%] flex gap-4">
                  <img
                    src={selectedOption.imageUrl}
                    alt={selectedOption.label}
                  />
                  <span>{selectedOption.label}</span>
                </div>
                <DownOutlined className="dropdown-icon" />
              </div>
            ) : (
              <div className="flex pr-4 w-full justify-between items-center">
                <p>Select an option</p>
                <DownOutlined className="dropdown-icon" />
              </div>
            )}
          </div>
          <ul
            className={`options-list absolute z-1000 top-16 w-[]  ${
              optionsMenu ? "flex" : "hidden"
            }`}
          >
            {options.map((option) => (
              <li
                key={option.value}
                onClick={() => {
                  handleOptionSelect(option);
                  setOptionsMenu(!optionsMenu);
                }}
                className="flex gap-4 hover:bg-[#432E5F57] px-2 py-2 rounded-md cursor-pointer
                "
              >
                <img src={option.imageUrl} alt={option.label} />
                <span>{option.label}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-center items-center gap-4 my-8">
          <div className="flex justify-center items-center w-[50%] lg:w-[50%] gap-4 text-white">
            <p className="text-[1.2rem] lg:text-4xl font-[600] whitespace-nowrap">
              Sight Shark
            </p>
            <img
              src="/Comparison/compare.svg"
              alt=""
              className="w-3/12 lg:w-full"
            />
            <p className="text-[1.2rem] lg:text-4xl font-[600]">
              {selectedOption.label}
            </p>
          </div>
        </div>
      </div>
      <div className="lg:flex hidden h-[auto] w-[85vw] mx-auto justify-between items-stretch text-white">
        <div className="flex flex-col justify-start items-center w-[19%] gap-10 text-lg font-[600] rounded-md py-8">
          <div className="h-[10vh] flex-col justify-center items-center"></div>
          {[
            "Focus",
            "Connectors",
            "Data Transformation",
            "Data Destinations",
            "Reporting & Insights",
            "Security",
            "Pricing",
            "Support",
          ].map((e, i) => {
            return (
              <p
                key={i}
                className="w-full h-12 otherSection flex justify-start items-center"
              >
                {e}
              </p>
            );
          })}
        </div>
        <div className="flex flex-col justify-center items-center w-[39%] gap-10 otherSection bg-gradient-to-br rounded-[1rem] from-[#ab609b] via-[#35045f] to-[#170a50] py-8 px-5">
          <div className="h-[10vh] flex flex-col justify-center items-center">
            <h2 className="otherSectionHeading font-[600] flex items-center justify-center">
              {" "}
              <img
                src="/logo.png"
                alt="Sight shark logo"
                className="w-[10vw] lg:w-[2.5vw] mr-2"
              />
              Sight Shark
            </h2>
          </div>
          <p className="h-12 text-center">
            Data Delivery & Actionable Insights
          </p>
          <p className="h-12 text-center">
            25+ sources (common & custom options)
          </p>
          <p className="h-12 text-center">
            Advanced data transformation capabilities
          </p>
          <p className="h-12 text-center">
            Flexible (Sheets, Excel, Databases, Warehouses)
          </p>
          <p className="h-12 text-center">
            Custom dashboards tailored to your needs
          </p>
          <p className="h-12 text-center">
            100% Secure & Private - Data in your cloud
          </p>
          <p className="h-12 text-center">
            One-time setup fee (cost-effective)
          </p>
          <p className="text-center h-12">
            Dedicated Project Lead (personalized)
          </p>
        </div>
        <div className="flex flex-col justify-center items-center w-[39%] gap-10 otherSection text-[#A7A7A7] rounded-[1rem] py-8 border-[3px] border-[#640DFB] px-5">
          <div className="h-[10vh] flex justify-center items-center gap-6">
            <img
              src={selectedOption.imageUrl}
              alt={selectedOption.label}
              className="w-[10vw] lg:w-[2.5vw] mr-2"
            />
            <span className="text-white otherSectionHeading font-[600]">
              {selectedOption.label}
            </span>
          </div>
          <p className="h-12 text-center">{selectedOption.focus}</p>
          <p className="h-12 text-center">{selectedOption.dataConnectors}</p>
          <p className="h-12 text-center">
            {selectedOption.dataTransformation}
          </p>
          <p className="h-12 text-center">{selectedOption.dataDestinations}</p>
          <p className="h-12 text-center">{selectedOption.reporting}</p>
          <p className="h-12 text-center">{selectedOption.security}</p>
          <p className="h-12 text-center">{selectedOption.pricing}</p>
          <p className="h-12 text-center">{selectedOption.support}</p>
        </div>
      </div>
      <div className="w-[85vw] mx-auto flex flex-col justify-center items-center gap-12 mt-12 lg:mt-32 text-white">
        <div className="flex flex-col justify-center items-start w-full lg:w-full gap-6">
          <div className="flex gap-4 items-center">
            <img
              src="/logo.png"
              alt="Sight shark logo"
              className="w-[10vw] lg:w-[2vw]"
            />
            <h2 className="otherSectionHeading font-[500]">
              Sight Shark Overview
            </h2>
          </div>
          <div className="flex flex-col justify-center items-center gap-6">
            <div className="flex justify-start w-full items-start gap-1 lg:gap-4">
              {/* <img src="/Comparison/tick_compare.svg" alt="" /> */}
              <p className="otherSection mt-2">
                <span className="font-[600] inline subSectionHeading">
                  Perfect For :{" "}
                </span>
                <br />
                <p className="inline otherSection text-grey">
                  {selectedOption.sightShark.perfectFor}
                </p>
              </p>
            </div>
            <div className="flex w-full justify-start items-start gap-1 lg:gap-4">
              {/* <img src="/Comparison/tick_compare.svg" alt="" className="" /> */}

              <p className="otherSection mt-2">
                <span className="font-[600] subSectionHeading">
                  Key Strengths :{" "}
                </span>
                <ol className="list-disc ml-4 lg:ml-8 mt-4 space-y-4 otherSection">
                  {selectedOption.sightShark.keyStrengths.map((item) => {
                    return (
                      <li>
                        <span className="font-[600] inline text-gray-200">
                          {item.key}:
                        </span>
                        <p className="inline ml-1 lg:ml-1 text-grey">
                          {item.value}
                        </p>
                      </li>
                    );
                  })}
                </ol>
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-start w-full lg:w-full gap-6 mt-16">
          <div className="flex gap-4 items-center">
            <img
              src={selectedOption.imageUrl}
              alt={"star"}
              className="w-[10vw] lg:w-[2vw]"
            />
            <h2 className="otherSectionHeading whitespace-nowrap font-[500]">
              {selectedOption.label} Overview
            </h2>
          </div>
          <div className="flex flex-col justify-center items-center gap-6">
            <div className="flex justify-center items-start gap-1 lg:gap-4">
              {/* <img src="/Comparison/tick_compare.svg" alt="" /> */}
              <p className="otherSection mt-2">
                <span className="font-[600] subSectionHeading">
                  Perfect For :{" "}
                </span>
                <br />
                <p className="otherSection inline text-grey">
                  {selectedOption.opponent.perfectFor}
                </p>
              </p>
            </div>
            <div className="flex justify-start w-full items-start gap-1 lg:gap-4">
              {/* <img src="/Comparison/tick_compare.svg" alt="" /> */}
              <p className="otherSection mt-2">
                <span className="font-[600] subSectionHeading">
                  Key Strengths :{" "}
                </span>
                <ol className="list-disc ml-4 lg:ml-8 mt-4 space-y-4 otherSection">
                  {selectedOption.opponent.keyStrengths.map((item) => {
                    return (
                      <li>
                        <span className="font-[600] text-gray-200">
                          {item.key}:
                        </span>
                        <p className="inline ml-1 text-grey">{item.value}</p>
                      </li>
                    );
                  })}
                </ol>
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-start w-full lg:w-full gap-6 mt-8 lg:mt-16">
          <div className="basis-[100%] flex gap-4">
            <span className="otherSectionHeading">
              Discover Sight Shark's Advantages over {selectedOption.label}
            </span>
          </div>
          <div className="flex flex-col justify-center items-center gap-6 w-full px-0 lg:px-3">
            {selectedOption.advantanges.map((item) => {
              return (
                <div className="flex justify-center items-start gap-4 w-full text-grey">
                  <img src="/Comparison/star.svg" alt="" className="mt-1" />
                  <p className="otherSection w-full">{item}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <CTA
        heading={"Don't just manage your data, master it. Choose Sight Shark."}
        text="Discover how Sight Shark can streamline your data delivery and empower data-driven decisions for your business!"
        button={"Schedule a call today"}
        onClick={() => setContactUsModal(true)}
        setContactUsModal={setContactUsModal}
        contactUsModal={contactUsModal}
      />
      <Footer />
    </div>
  );
}

export default Compare;
