import React from "react";

const ConnectorImg = () => {
  const connectorsData = [
    {
      title: "Amazon Selling Partner",
      img: "/Logos/Amazon Selling Partner.svg",
    },
    {
      title: "Bamboo HR",
      img: "/Logos/BambooHR.svg",
    },
    {
      title: "Facebook Ads",
      img: "/Logos/Facebook Ads.svg",
    },
    {
      title: "Facebook Insights",
      img: "/Logos/Facebook Insights.svg",
    },
    {
      title: "Google Ads Manager",
      img: "/Logos/Google Ads Manager.svg",
    },
    {
      title: "Google Ads",
      img: "/Logos/Google Ads.svg",
    },
    {
      title: "Google Analytics",
      img: "/Logos/Google Analytics 4.svg",
    },
    {
      title: "Google DV360",
      img: "/Logos/Google DV360.svg",
    },
    {
      title: "Google My Business",
      img: "/Logos/Google My Business.svg",
    },
    {
      title: "Google Search Console",
      img: "/Logos/Google Search Console.svg",
    },
    {
      title: "Google Sheets",
      img: "/Logos/Google Sheets.svg",
    },
    {
      title: "HubSpot",
      img: "/Logos/HubSpot.svg",
    },
    {
      title: "Instagram Ads",
      img: "/Logos/Instagram Ads.svg",
    },
    {
      title: "Instagram Insights",
      img: "/Logos/Instagram Insights.svg",
    },
    {
      title: "JSON",
      img: "/Logos/JSON.svg",
    },
    {
      title: "Klaviyo",
      img: "/Logos/Klaviyo.svg",
    },
    {
      title: "LinkedIn",
      img: "/Logos/LinkedIn.svg",
    },
    {
      title: "Outbrain",
      img: "/Logos/Outbrain.svg",
    },
    {
      title: "PayPal",
      img: "/Logos/PayPal.svg",
    },
    {
      title: "Shopify",
      img: "/Logos/Shopify.svg",
    },
    {
      title: "Stripe",
      img: "/Logos/Stripe.svg",
    },
    {
      title: "Taboola",
      img: "/Logos/Taboola.svg",
    },
    {
      title: "TikTok",
      img: "/Logos/TikTok.svg",
    },
    {
      title: "X Ads",
      img: "/Logos/X Ads.svg",
    },
    {
      title: "Xero",
      img: "/Logos/Xero.svg",
    },
    {
      title: "Klaviyo",
      img: "/Logos/Klaviyo.svg",
    },
    {
      title: "YouTube",
      img: "/Logos/YouTube.svg",
    },
  ];

  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 md:grid-cols-6 gap-x-8 lg:gap-x-10 gap-y-8 lg:gap-y-10">
      {connectorsData?.map((e, i) => {
        return (
          <div
            key={i}
            className="video-box-shadow py-12 rounded-3xl hover:rounded-xl hover:scale-105 transition-all cursor-pointer flex flex-col text-white justify-center items-center lg:px-0 px-1"
          >
            <img src={e?.img} alt="Img" className="w-4/12 aspect-square" />
            <p className="text-sm mt-6 text-center">{e?.title}</p>
          </div>
        );
      })}
    </div>
  );
};

export default ConnectorImg;
