import React from "react";
import { useNavigate } from "react-router-dom";
import { case_studies } from "../Pages/IndividualCase";
import { FaArrowRight } from "react-icons/fa";

function CaseCards() {
  return (
    <div className="w-[85vw] flex flex-row justify-around items-center">
      <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-10 w-full">
        {case_studies.map((item, index) => {
          return <Block key={index} item={item} index={index} />;
        })}
      </div>
    </div>
  );
}

const Block = ({ item, index }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={(e) => {
        let id = item?.title
          ?.toLowerCase()
          ?.replaceAll(" ", "-")
          ?.replaceAll(",", "");
        navigate(`/case-studies/${id}`);
      }}
      className="rounded-lg overflow-hidden border-2 bg-[#0D003D] border-[#0057ff33] p-2"
    >
      <img
        src={item?.image}
        alt="Image"
        className="rounded-lg cursor-pointer h-[20vh] w-full object-cover hover:scale-105 transition-all"
      />
      <div className="text-white mt-3">
        <h1 className="subSectionHeading font-medium lg:font-semibold">
          {item?.title}
        </h1>
        <div className="my-2">
          {item?.tags?.split("|")?.map((e) => {
            return (
              <li key={e} className="text-[#CACACA] footerSections">
                {e}
              </li>
            );
          })}
        </div>
        <button className="w-full font-semibold py-1.5 text-start rounded-md subSection flex items-center">
          View Case Study{" "}
          <FaArrowRight className="rotate-[-45deg] ml-1 text-base" />
        </button>
      </div>
    </div>
  );
};

export default CaseCards;
