import React from "react";
import MailIcon from "../Assets/ContactUs/mail.svg";
import LinkedInIcon from "../Assets/ContactUs/linkedin.svg";
import { InlineWidget, PopupModal, PopupWidget } from "react-calendly";
import { Link } from "react-router-dom";
import IconCloud from "../Components/Contact/Cloud";

function ContactUs() {
  return (
    <div
      id="contactus"
      className="h-[auto] lg:h-[80vh] lg:gap-0 gap-16 flex flex-col lg:flex-row justify-evenly my-8 lg:my-14 w-[88vw]"
    >
      <div className="lg:w-[27vw] flex flex-col justify-center items-center gap-4 lg:gap-8 text-white">
        <h1 className="otherSectionHeading text-white font-[500]">
          Contact Us
        </h1>
        <div className="w-full flex items-center justify-center">
          <IconCloud
            iconSlugs={[
              "amazon",
              "facebook",
              "googleads",
              "googleanalytics",
              "googlesearchconsole",
              "googlesheets",
              "hubspot",
              "instagram",
              "linkedin",
              "paypal",
              "shopify",
              "stripe",
              "xero",
              "youtube",
              "mysql",
              "amazonrds",
              "amazonredshift",
              "googlebigquery",
              "googlecloudstorage",
              "postgresql",
              "snowflake",
            ]}
          />
        </div>
        <p className="text-[#A4A3A9] text-center otherSection">
          {/* Feel free to contact us via the contact form for any questions or
          issues. Our team is ready to assist you promptly. */}
          Say goodbye to manual entry and static spreadsheets. Automate your
          data extraction and visualization processes today. Book a demo now!
        </p>
        <div className="flex flex-row gap-8 text-[#A4A3A9]">
          <div className="flex flex-row gap-2 justify-center items-center">
            <img src={MailIcon} alt="" />
            <p>
              <a href="mailto:team@sightshark.com">Email</a>
            </p>
          </div>
          <Link to="https://www.linkedin.com/company/99902403">
            <div className="flex flex-row gap-2 justify-center items-center">
              <img src={LinkedInIcon} alt="" />
              <p>LinkedIn</p>
            </div>
          </Link>
        </div>
      </div>
      <div className="w-[100vw] h-auto lg:w-[30vw] lg:h-[80vh] flex flex-col justify-center items-center">
        <InlineWidget
          url="https://calendly.com/rishabh-prowiz/30min"
          className="react-calendy-div"
        />
      </div>
    </div>
  );
}

export default ContactUs;
