import React from "react";
import { PopupModal } from "react-calendly";

const CTA = ({
  heading,
  text,
  button,
  onClick,
  setContactUsModal,
  contactUsModal,
}) => {
  return (
    <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center w-[95vw] mx-auto rounded-[10px] connector-request-container lg:py-24 py-12 mb-24 px-4 lg:px-16 mt-28">
      <div className="flex flex-col justify-around items-center lg:items-start gap-2 lg:gap-6">
        <p className="text-white font-[600] otherSectionHeading text-center lg:text-start">
          {heading}
        </p>
        {text && (
          <p className="text-[#7E7C86] text-center lg:text-start otherSection">
            {text}
          </p>
        )}
      </div>
      <div className="text-white lg:mr-[5%] lg:mt-0 mt-5">
        <button onClick={onClick} className="mainButton">
          {button}
        </button>
      </div>
      <PopupModal
        url="https://calendly.com/rishabh-prowiz/30min"
        rootElement={document.getElementById("root")}
        open={contactUsModal}
        onModalClose={() => setContactUsModal(false)}
      />
    </div>
  );
};

export default CTA;
