import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";

function ViewReportLive({ reports }) {
  const { idName } = useParams();
  const [report, setReport] = useState();
  const [id, setId] = useState(0);
  // const report = reports[id];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let data = reports?.find(
      (e) =>
        e?.title?.toLowerCase()?.replaceAll(" ", "-")?.replaceAll(",", "") ===
        idName
    );
    let idx = reports?.findIndex(
      (e) =>
        e?.title?.toLowerCase()?.replaceAll(" ", "-")?.replaceAll(",", "") ===
        idName
    );
    // setCaseStudy(data);
    setReport(data);
    setId(idx);
  }, [idName]);

  return (
    <div className="">
      {/* <Navbar /> */}
      <div>
        <iframe
          src={report?.link}
          frameborder="0"
          style={{ border: "none" }}
          className="w-full h-[100vh]"
          allowfullscreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        ></iframe>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
export default ViewReportLive;
