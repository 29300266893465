import React from "react";
import "video-react/dist/video-react.css";
import AiGif from "../Assets/marketing_campaign.gif";

function Features() {
  const data = [
    {
      title: (
        <span>
          Fully <span style={{ color: "#0057e1" }}>customized</span> and
          User-friendly <span style={{ color: "#0057e1" }}>dashboards</span>!
        </span>
      ),
      description:
        "We tailor dashboards to highlight your preferred insights on your chosen tools with precision.",
      image: "/features/feature/4.png",
    },
    {
      title: (
        <span>
          Artificial Intelligence (<span style={{ color: "#0057e1" }}>AI</span>){" "}
          powered <span style={{ color: "#0057e1" }}>insights</span>!
        </span>
      ),
      description:
        "Our in-house chatbot Shark Eye provides insights and recommendations based on your data. Saving you time and effort to update your reports.",
      image: "/features/feature/3.png",
    },
    {
      title: (
        <span>
          Best <span style={{ color: "#0057e1" }}>price-to-value</span> ratio in
          the market!
        </span>
      ),
      description:
        "Get from data to insights at 50% lower prices than competition. We do not follow an exorbitant and extractive yearly subscription pricing model.",
      image: "/features/feature/1.png",
    },
    {
      title: (
        <span>
          100% <span style={{ color: "#0057e1" }}>Secure</span> and Private!
        </span>
      ),
      description:
        "Sight Shark operates in your cloud, securing your data from third party access, providing unparalleled privacy and security.",
      image: "/features/feature/2.png",
    },
  ];

  return (
    <div className="flex flex-row justify-center items-center w-[80vw] mt-8 lg:mt-16">
      <div className="flex flex-col gap-20 w-[90vw] lg:w-[80vw]">
        {data.map((item, index) => {
          return (
            <div
              className={`flex ${
                index % 2 === 1
                  ? "flex-col lg:flex-row"
                  : "lg:flex-row-reverse flex-col"
              } justify-center items-center gap-2 lg:gap-8 mt-2`}
              key={index}
            >
              <div
                className={`flex flex-col basis-full lg:basis-[60%] justify-center ${
                  (index + 1) % 2 === 1
                    ? "items-center lg:items-end"
                    : "items-center lg:items-start"
                } gap-6`}
              >
                <div className="flex flex-col justify-center items-start w-full lg:w-[80%] lg:gap-4 gap-1">
                  <h2 className="text-white text-start subSubSectionHeading">
                    {item.title}
                  </h2>
                  <p className="text-[#CACACA] text-start subSection">
                    {item.description}
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-[60vw] lg:basis-[50%] rounded-md">
                <img
                  src={item.image ? item?.image : AiGif}
                  alt=""
                  className="w-full object-contain"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Features;
