import React, { useEffect, useRef, useState } from "react";
import { FaStar } from "react-icons/fa";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

let data = [
  {
    name: "Johnson & Johnson",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUpbv2BX1aOnMZMPoFgJRIKQz6jvni-MMJYA&s",
    desc: "The team's expertise in creating a customized Power BI dashboard, despite initial data ambiguity, resulted in a top-notch dashboard that sets new standards for functionality and insight.",
  },
  {
    name: "Proximo Spirits",
    img: "/testimonials/Proximo-Logo.png",
    desc: "SightShark delivered excellent Tableau dashboards and I enjoyed working with them. They were quick to respond to messages, met all deadlines, and did a great job of interpreting our requirements. ",
  },
  {
    name: "Advant Labs",
    img: "/testimonials/advant_logo1_black.png",
    desc: "SightShark excels in Google Looker, integrating data sources and creating intuitive, insightful visualizations. They tailored the dashboard to meet my business needs, providing the precise insights needed for informed decision-making.",
  },
  {
    name: "CMI Packaging",
    img: "/testimonials/cmi_packaging.jpg",
    desc: "SightShark's Tableau dashboards transformed our company's operations. They developed comprehensive dashboards for sourcing, inventory management, sales, and more, providing clear and actionable insights. Their expertise in Tableau has greatly enhanced our data visualization and decision-making processes.",
  },
  {
    name: "Dolphin Analytics",
    img: "/testimonials/dolphin_analytics.png",
    desc: "SightShark is an exceptional asset for companies seeking to enhance data presentation using Google Data Studio or other dashboards, consistently providing exceptional dashboard updates and improved visuals.",
  },
  {
    name: "Top Line Media",
    img: "/testimonials/logo-top-1.png",
    desc: "SightShark successfully created a complex Looker studio report, integrating various platforms and software, and successfully completed the project, recommending their services for future projects.",
  },
  {
    name: "Metropolis",
    img: "/testimonials/metropolis.jpg",
    desc: "SightShark provided a scalable, automated reporting solution for our marketing agency, integrating data from 10+ sources and creating tailored Looker Studio dashboards for 50+ clients. The workflow to create reports for new clients with minimal technical knowledge has greatly improved our operations.",
  },
];

const Testimonals = () => {
  return (
    <div className="mt-10">
      <Swiper
        slidesPerView={
          typeof window != "undefined"
            ? window.innerWidth < 600
              ? 1
              : window.innerWidth < 1000
              ? 2
              : 3
            : 0
        }
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        loop={true}
        autoplay={{
          interval: 1000,
          disableOnInteraction: false,
        }}
      >
        {data?.map((e, i) => {
          return (
            <SwiperSlide key={i}>
              <LetBlock data={e} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

const LetBlock = ({ data }) => {
  return (
    <div id="testBlock" className="mx-auto w-11/12 py-5">
      <div className="flex items-start relative z-40">
        <div className="bg-white w-1/5 min-[500px]:top-[25%] aspect-square flex items-center justify-center p-1.5 rounded-full">
          <img
            src={data?.img}
            className="rounded-full aspect-square object-contain"
          />
        </div>
        <div className="flex items-center ml-3 absolute min-[300px]:-top-5 min-[350px]:-top-4 min-[400px]:-top-1.5 min-[450px]:top-0 min-[550px]:top-2 min-[600px]:-top-6 min-[800px]:-top-2 min-[1000px]:-top-4 min-[1400px]:top-1 min-[1800px]:top-3 left-[22%]">
          {[1, 2, 3, 4, 5].map((e) => {
            return <FaStar key={e} className="text-yellow-600 text-2xl mr-2" />;
          })}
        </div>
      </div>
      <div className="bg-[#091441] flex flex-col items-end -mt-10 rounded-xl px-5 py-6 min-[300px]:h-[102vw] min-[350px]:h-[22vw] min-[400px]:h-[53vw] min-[450px]:h-[43vw] min-[550px]:h-[30vw] min-[600px]:h-[48vw] min-[700px]:h-[40vw] min-[800px]:h-[28vw] min-[850px]:h-[53vw] min-[900px]:h-[40vw] min-[1000px]:h-[60vw] min-[1050px]:h-[54vw] min-[1100px]:h-[46vw] min-[1200px]:h-[38vw] min-[1300px]:h-[32vw] min-[1400px]:h-[25vw] min-[1600px]:h-[20vw] min-[1750px]:h-[18vw]">
        <h1 className="text-white font-semibold subSubSectionHeading text-end w-[75%] mb-1 -mt-2">
          {data?.name}
        </h1>
        <p className="text-[#CACACA] subSection">
          <span className="text-[16px] lg:text-2xl">"</span>
          {data?.desc}
          <span className="text-[16px] lg:text-2xl">"</span>
        </p>
      </div>
    </div>
  );
};

export default Testimonals;
