import React from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function Partners() {
  const partners = [
    { img: "/Partners/CMI.png" },
    { img: "/Partners/DFTBA.png" },
    { img: "/Partners/Framestore.png" },
    { img: "/Partners/HUL.png" },
    { img: "/Partners/Instructure.png" },
    { img: "/Partners/J&J.png" },
    { img: "/Partners/Mitratech.png" },
    { img: "/Partners/Placester.png" },
    { img: "/Partners/Proximo.png" },
  ];

  return (
    <div className="w-[92vw] lg:w-[86vw] mx-auto mt-4 lg:mt-8">
      <Swiper
        slidesPerView={
          typeof window != "undefined" ? (window.innerWidth < 550 ? 3 : 4) : 0
        }
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        loop={true}
        autoplay={{
          interval: 3000,
          disableOnInteraction: false,
        }}
      >
        {partners?.map((item, i) => (
          <SwiperSlide key={i} as={"image"}>
            <img
              src={item?.img}
              alt="Slide"
              className="w-11/12 mx-auto lg:w-[14vw]"
              width={1000}
              height={1000}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Partners;
