import React from "react";
import { useNavigate } from "react-router-dom";

function Dashboards({ reports, filter }) {
  const navigate = useNavigate();

  return (
    <div className="w-[90vw] grid grid-cols-1 lg:grid-cols-2 md:grid-cols-3 gap-y-10 lg:gap-y-20 lg:gap-x-12">
      {reports
        ?.filter((e) => {
          if (filter !== "All") {
            return e?.type?.toLowerCase().includes(filter?.toLowerCase());
          } else {
            return e;
          }
        })
        .map((item, index) => {
          return (
            <div
              key={index}
              onClick={(e) => {
                let index = item?.title
                  ?.toLowerCase()
                  ?.replaceAll(" ", "-")
                  ?.replaceAll(",", "");
                navigate(`/reports/${index}`);
              }}
              className="flex flex-row w-full justify-center items-center cursor-pointer"
            >
              <div className="dashboard-cards report-dashboard border border-[#191230] rounded-[15px] flex flex-col px-0 py-0 text-white gap-1 bg-[#181133]">
                <img
                  className="h-[40vh] object-cover object-top rounded-[15px] w-[100vw] lg:w-[50vw] md:w-[23vw] p-3"
                  src={item?.headings[0].images}
                  alt="Image"
                />
                <div className="flex flex-row justify-center pb-4 items-center">
                  <p className="otherSection text-center lg:py-2">
                    {item?.title}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default Dashboards;
