import gsap from "gsap";
import React, { useEffect } from "react";

const Heading = ({ text }) => {
  useEffect(() => {
    gsap.to("#bg", {
      duration: 2,
      width: "100%",
      ease: "power2.inOut",
    });
  }, []);

  return (
    <div className="inline-block">
      {/* <div class="bg-[#9e20ff] w-[6px] h-[7vh] mb-2 inline-block align-middle"></div> */}
      <span className="relative">
        <span id="title" className="bg-transparent">
          {text}
        </span>
        <div
          id="bg"
          className="bg-gradient-to-r inline-block from-[#0057e1] to-[#0057e1]/0 left-0 top-1/2 -translate-y-1/2 z-10 text-white absolute w-0 whitespace-nowrap"
        >
          {text}
        </div>
      </span>
    </div>
  );
};

export default Heading;
