import React from "react";
import { Collapse, ConfigProvider } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

function FAQ() {
  const items = [
    {
      key: "1",
      label: (
        <p className="text-[14px] lg:text-[1.25rem]">
          How many data sources does Sight Shark support, and in how many
          databases can the extracted data be stored?
        </p>
      ),
      children: (
        <p className="subSection text-[#CACACA]">
          Sight Shark has the capability of extracting data from over 20 sources
          including databases, file formats such as CSV, JSON, and APIs. The
          extracted data can be stored in 10+ databases, offering users the
          flexibility to choose the storage solution that best suits their
          needs. For a detailed list, click here. ( redirect to connectors page)
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <p className="text-[14px] lg:text-[1.25rem]">
          Is Sight Shark suitable for small businesses or only enterprise-level
          use?
        </p>
      ),
      children: (
        <p className="subSection text-[#CACACA]">
          Sight Shark is suitable for businesses of all sizes. Whether you're a
          small business or an enterprise, Sight Shark offers scalable solutions
          to meet your data extraction, storage, and visualisation needs.
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <p className="text-[14px] lg:text-[1.25rem]">
          How secure is the data extraction process?
        </p>
      ),
      children: (
        <p className="subSection text-[#CACACA]">
          The data extraction process in Sight Shark is highly secure. The
          entire setup is executed in your cloud environment, ensuring that your
          data remains under your control and within the security parameters of
          your cloud infrastructure.
        </p>
      ),
    },
    {
      key: "4",
      label: (
        <p className="text-[14px] lg:text-[1.25rem]">
          Does Sight Shark offer the capability to generate dashboards at scale?
        </p>
      ),
      children: (
        <p className="subSection text-[#CACACA]">
          Yes, Sight Shark is equipped to generate dashboards at scale.
          Especially beneficial for agencies with multiple clients, Sight Shark
          can swiftly create individual dashboards for each client incorporating
          their specific data. This process is streamlined and efficient,
          leveraging a dashboard template to create personalised dashboards for
          each client in just minutes.
        </p>
      ),
    },
    {
      key: "5",
      label: (
        <p className="text-[14px] lg:text-[1.25rem]">
          What is the pricing model for Sight Shark?
        </p>
      ),
      children: (
        <p className="subSection text-[#CACACA]">
          Sight Shark operates on a unique pricing model. We charge a one-time
          setup fee based on the number of data sources and the preferred
          database of our clients. Unlike competitors with monthly or yearly
          subscriptions, we do not impose ongoing fees. Clients can save
          approximately 70% over a 3-year period compared to competitors with
          hefty subscription charges.To know more, contact us ( contact us form
          link)
        </p>
      ),
    },
    {
      key: "6",
      label: (
        <p className="text-[14px] lg:text-[1.25rem]">
          Is there any warranty for Sight Shark setup?
        </p>
      ),
      children: (
        <p className="subSection text-[#CACACA]">
          Yes, we offer a 6-month warranty for Sight Shark setup. In the event
          of any disruption, our dedicated team ensures a swift resolution, with
          problems addressed within 24 hours to minimise downtime for our
          clients.
        </p>
      ),
    },
  ];

  return (
    <div className="w-[80vw] flex flex-col justify-around my-10 lg:my-20 gap-8 px-0 lg:px-0 2xl:px-0">
      <h2 className="otherSectionHeading font-[500] text-white text-center">
        Frequently Asked Questions
      </h2>
      <div className="mt-4 lg:mt-8">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#00b96b",
              borderRadius: 2,
              headerBg: "rgb(25, 19, 48,0.1)",
              colorBgContainer: "#f6ffed",
            },
          }}
        >
          <Collapse
            items={items}
            expandIconPosition={"end"}
            expandIcon={({ isActive }) =>
              !isActive
                ? window?.innerWidth > 600 && (
                    <PlusCircleOutlined
                      fill="#ffffff"
                      className="otherSection hidden lg:block"
                    />
                  )
                : window?.innerWidth > 600 && (
                    <MinusCircleOutlined
                      fill="#fff"
                      className="otherSection hidden lg:block"
                    />
                  )
            }
            className="border-none text-white"
          />
        </ConfigProvider>
      </div>
    </div>
  );
}

export default FAQ;
