import gsap from "gsap";
import React from "react";

const Line = () => {
  const enterAnimate = (e) => {
    gsap.to("svg path", {
      attr: {
        d: `M 0 100 Q ${e?.nativeEvent?.offsetX} ${e?.nativeEvent?.offsetY} 1700 100`,
      },
    });
  };

  return (
    <>
      <div className="w-[88vw] my-4 mx-auto h-[1px] bg-gray-500 bg-gradient gradient-line opacity-40"></div>
      {/* <div
      className="w-[85vw] mx-auto"
      onMouseMove={(e) => {
        enterAnimate(e);
      }}
      onMouseLeave={(e) => {
        gsap.to("svg path", {
          attr: {
            d: `M 0 100 Q 52.5 100 1700 100`,
          },
          ease: "elastic.out(1,0.3)",
        });
      }}
    >
      <svg height="200" className="w-full overflow-x-hidden">
        <path d="M 0 100 Q 52.5 100 1700 100" stroke="white" fill="transparent" />
      </svg>
    </div> */}
    </>
  );
};

export default Line;
