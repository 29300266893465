import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import CaseCards from "../Components/CaseCards";
import LandingSection from "../Components/Utils/LandingSection";

function CaseStudies() {
  return (
    <div className="w-full bg-[#030020] pt-[90px] lg:pt-[120px]">
      <Navbar />
      <LandingSection
        heading="Understanding SightShark's capabilities through real-world applications"
        text="Explore the real world application of SightShark and how we transform data into strategic insights"
      />
      <div className="w-full flex flex-row justify-center items-center mb-[15vh]">
        <CaseCards />
      </div>
      <Footer />
    </div>
  );
}

export default CaseStudies;
