export function OrbitingCirclesDemo() {
  return (
    <div className="relative">
      <div className="container grid h-[20vw] z-50 relative place-items-center overflow-hidden p-[4vw]">
        <div className="hero-circle relative aspect-square h-full">
          <img
            className="inner-img absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[50px] h-[50px] overflow-hidden z-20"
            src="/logo.png"
            alt=""
          />
          <div
            className="hero-rotate relative border-[7px] border-[#9665d8] w-full h-full top-[-7px] left-[-7px] rounded-full bg-[#6700ef]"
            id="circle"
          >
            {[
              "/Logos/Outbrain.svg",
              "/Logos/X Ads.svg",
              "/Logos/Stripe.svg",
              "/Logos/Google Ads Manager.svg",
            ].map((e, i) => {
              return (
                <div
                  className="planet absolute rounded-full aspect-square"
                  key={i}
                >
                  <img
                    src={e}
                    alt=""
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>{" "}
      <div className="container absolute top-1/2 -translate-y-1/2 right-10 z-40 grid h-[29vw] place-items-center overflow-hidden p-[4vw]">
        <div className="hero-circle relative aspect-square h-full">
          <div
            className="hero-rotate relative border-[7px] border-[#76559c] w-full h-full top-[-7px] left-[-7px] rounded-full bg-[#43009b]"
            id="circle"
          >
            {[
              "/Logos/Google DV360.svg",
              "/Logos/JSON.svg",
              "/Logos/Google My Business.svg",
              "/Logos/Xero.svg",
            ].map((e, i) => {
              return (
                <div
                  className="planet absolute rounded-full aspect-square"
                  key={i}
                >
                  <img
                    src={e}
                    alt=""
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="container absolute top-1/2 -translate-y-1/2 right-[7vw] z-30 grid h-[34vw] place-items-center overflow-hidden p-[4vw]">
        <div className="hero-circle relative aspect-square h-full">
          <div
            className="hero-rotate relative border-[7px] border-[#76559c] w-full h-full top-[-7px] left-[-7px] rounded-full bg-[#2b0167]"
            id="circle"
          >
            {[
              "/Logos/Facebook Ads.svg",
              "/Logos/Instagram Ads.svg",
              "/Logos/YouTube.svg",
              "/Logos/TikTok.svg",
            ].map((e, i) => {
              return (
                <div
                  className="planet absolute rounded-full aspect-square"
                  key={i}
                >
                  <img
                    src={e}
                    alt=""
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
