import React from "react";
import Heading from "./Animations/Heading";
import { useNavigate } from "react-router-dom";

const LandingPage = ({ scrollToDiv }) => {
  return (
    <div className="backgroundimg h-6/12 lg:h-[90vh] w-[100vw] flex flex-col justify-center items-center gap-0 lg:gap-20">
      <div className="flex flex-col items-center justify-center gap-4 lg:gap-8">
        <div className="grid grid-cols-2 lg:grid-cols-4 items-center lg:w-8/12 w-10/12 text-[#AE79D6] font-[300] gap-4 lg:gap-8 text-[14px] lg:text-[1.25rem] lg:leading-[30px] mb-2 lg:mb-8">
          <p className="text-center whitespace-nowrap">Customized Dashboard</p>
          <p className="text-center whitespace-nowrap">AI-Powered Insights</p>
          <p className="text-center whitespace-nowrap">Best price to value</p>
          <p className="text-center whitespace-nowrap">100% Secure</p>
        </div>
        <h1 className="mainHeading">
          Scalable and Reliable Infrastructure For{" "}
          <Heading text={"Business Insights"} />
        </h1>
        <p className="text-[#CACACA] mainSection text-center w-11/12 lg:w-6/12">
          Sight Shark extracts data and transforms into AI-powered dashboards
          with ease, just a few clicks, and cost savings.
        </p>
      </div>
      <button
        className="mainButton mt-5 md:mt-0 lg:mb-0 mb-14"
        onClick={() => {
          document.getElementById("whatOffer").scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }}
      >
        Get Started
      </button>
    </div>
  );
};

export default LandingPage;
