import React from "react";

const DatabaseImg = () => {
  const databaseData = [
    {
      title: "Amazon S3",
      img: "/Logos/Connectors/Amazon S3.svg",
    },
    {
      title: "AWS RDS",
      img: "/Logos/Connectors/AWS RDS.svg",
    },
    {
      title: "AWS Redshift",
      img: "/Logos/Connectors/AWS Redshift.svg",
    },
    {
      title: "Azure SQL",
      img: "/Logos/Connectors/Azure SQL.svg",
    },
    {
      title: "Blob Storage",
      img: "/Logos/Connectors/Blob Storage.svg",
    },
    {
      title: "Google BigQuery",
      img: "/Logos/Connectors/Google BigQuery.svg",
    },
    {
      title: "Google Cloud Storage",
      img: "/Logos/Connectors/Google Cloud Storage.svg",
    },
    {
      title: "Google Sheets",
      img: "/Logos/Connectors/Google Sheets.svg",
    },
    {
      title: "MSSQL",
      img: "/Logos/Connectors/MSSQL.svg",
    },
    {
      title: "MySQL",
      img: "/Logos/Connectors/MySQL.svg",
    },
    {
      title: "PostgreSQL",
      img: "/Logos/Connectors/PostgreSQL.svg",
    },
    {
      title: "Snowflake",
      img: "/Logos/Connectors/Snowflake.svg",
    },
  ];

  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 md:grid-cols-6 gap-x-8 lg:gap-x-12 gap-y-8 lg:gap-y-10">
      {databaseData?.map((e, i) => {
        return (
          <div
            key={i}
            className="video-box-shadow py-12 rounded-3xl hover:rounded-xl hover:scale-105 transition-all cursor-pointer flex flex-col text-white justify-center items-center lg:px-0 px-1"
          >
            <img src={e?.img} alt="Img" className="w-4/12 aspect-square" />
            <p className="text-sm mt-6 text-center">{e?.title}</p>
          </div>
        );
      })}
    </div>
  );
};

export default DatabaseImg;
